<template>
  <div class="">
    <div ref="container"></div>

    <div v-if="item.formData.type == 1" class="data-form">
      <b-row>
        <b-col cols="6">
          <b-form-group>
            <span class="text-muted" style="font-size: 12px">
              ID веб-мастера
              <feather-icon
                icon="CopyIcon"
                class="text-muted"
                style="cursor: pointer"
                @click="copy(item.user.uuid)"
            /></span>
            <p class="m-0">{{ item.user.uuid }}</p>
          </b-form-group>
        </b-col>

        <b-col cols="6">
          <b-form-group>
            <span class="text-muted" style="font-size: 12px">
              ID продукта
              <feather-icon
                icon="CopyIcon"
                class="text-muted"
                style="cursor: pointer"
                @click="copy(item.offer.id)"
            /></span>
            <p class="m-0">{{ item.offer.id }}</p>
          </b-form-group>
        </b-col>
      </b-row>

      <hr class="light" style="margin: 16px" />

      <b-row>
        <b-col cols="6">
          <b-form-group>
            <span class="text-muted" style="font-size: 12px">
              Способ привлечения клиентов
              <feather-icon
                icon="CopyIcon"
                class="text-muted"
                style="cursor: pointer"
                @click="copy(item.formData.data.attraction)"
            /></span>
            <p class="m-0">{{ item.formData.data.attraction }}</p>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group>
            <span class="text-muted" style="font-size: 12px">
              Источник трафика (ссылка на ресурс)
              <feather-icon
                icon="CopyIcon"
                class="text-muted"
                style="cursor: pointer"
                @click="copy(item.formData.data.placement_creative)"
            /></span>
            <p class="m-0">{{ item.formData.data.placement_creative }}</p>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="6">
          <b-form-group>
            <span class="text-muted" style="font-size: 12px">
              Прогнозируемое кол-во заявок в месяц
              <feather-icon
                icon="CopyIcon"
                class="text-muted"
                style="cursor: pointer"
                @click="copy(item.formData.data.projected_quantity)"
            /></span>
            <p class="m-0">{{ item.formData.data.projected_quantity }}</p>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group>
            <span class="text-muted" style="font-size: 12px">
              Креатив размещения
              <feather-icon
                icon="CopyIcon"
                class="text-muted"
                style="cursor: pointer"
                @click="copy(item.formData.data.source)"
            /></span>
            <p class="m-0">{{ item.formData.data.source }}</p>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  data() {
    return {};
  },
  props: {
    item: Object,
  },
  methods: {
    copy(content) {
      let container = this.$refs.container;
      this.$copyText(content.toString(), container);

      this.$toast({
        component: ToastificationContent,
        props: {
          text: "Скопировано в буфер обмена.",
          icon: "CheckCircleIcon",
          variant: "success",
        },
      });
    },
  },
  mounted() {
    console.log(this.item);
  },
};
</script>

<style></style>

<template>
<div class="mt-2">
<div class="d-flex align-items-center mb-2 justify-content-between">

    <div class="d-flex ">
    <span class="mr-1 text-muted"> Новый статус </span>
    <div class="d-flex align-items-center" v-if="item.status == 0">
                <div class="cicle-badge-view cicle-default"></div>
              </div>
              <div
                class="d-flex align-items-center"
                v-else-if="item.status == 1"
              >
                <div class="cicle-badge-view cicle-outline-default"></div>
              </div>
              <div
                class="d-flex align-items-center"
                v-else-if="item.status == 2"
              >
                <div class="cicle-badge-view cicle-outline-warning"></div>
              </div>
              <div
                class="d-flex align-items-center"
                v-else-if="item.status == 3"
              >
                <div class="cicle-badge-view cicle-outline-success"></div>
              </div>
    </div>
   <b-form-select
        style="width: 200px; border-radius: 16px"
        v-model="item.status"
        :options="statuses"
        size="lg"
        class="ml-2"
    ></b-form-select>
</div>
    <b-button @click="save" variant="primary" block>
        Сохранить
    </b-button>
</div>
</template>

<script>
export default {
    data() {
        return {
        }
    },
    props: {
        statuses: Array,
        item: Object
    },
    methods: {
        save() {
            this.$emit('afterChangeStatus', this.item)
        }
    },
    mounted() {
        console.log(this.item)
    }
}
</script>

<style>

</style>
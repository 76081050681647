

    import $request from "@/modules/request"
    
    export default {
            
        dataSource: {
            items: [],
            current_page: 1,
            has_more_pages: false,
            last_page: 1,
            total_count: 0
        },    
               
            
        get( forceUpdate = false ) {
            $request.get("users/list").then( rsp => {
                Object.keys( this.dataSource ).forEach( key => {
                    if( rsp.hasOwnProperty(key) ) {
                        this.dataSource[key] = rsp[key];
                    }
                });
            });
        },
        
        
        utils: {
            
            capitalize(name, countOfChars) {
            
                if( countOfChars > 1 ) {
                    var arrOfName = name.split(" ");
                    if(arrOfName && arrOfName.length > 1) {
                        return arrOfName[0].charAt(0) + arrOfName[1].charAt(0);
                    } else {
                        return name.substr( 0, countOfChars );
                    }
                } else {
                    return name.substr( 0, countOfChars );
                }

            },
            
            resolveAvatarColor( id ) {
                
                let str = id.toString();
                let char = str.slice(-1);
                let number = parseInt(char);
                
                switch( number ) {
                    case 1: return 'light-primary';
                    case 2: return 'light-success';
                    case 3: return 'light-danger';
                    case 4: return 'light-info';
                    case 5: return 'light-warning';
                    case 6: return 'light-success';
                    case 7: return 'light-primary';
                    case 8: return 'light-warning';
                    case 9: return 'light-danger';
                    case 0: return 'light-info'; 
                }
                
                
            },
            
            resolveUserGroupName( group ) {
                switch( group ) {
                    case 1: return 'Пользователь';
                    case 2: return 'Редактор';
                    case 3: return 'Менеджер';
                    case 4: return 'Поддержка';
                    case 5: return 'Администратор';
                }
            },
            resolveUserGroupIcon( group ) {
                switch( group ) {
                    case 1: return 'UserIcon';
                    case 2: return 'Edit2Icon';
                    case 3: return 'SettingsIcon';
                    case 4: return 'MessageCircleIcon';
                    case 5: return 'ShieldIcon';
                }
            },
            resolveUserGroupIconColor( group ) {
                switch( group ) {
                    case 1: return 'text-primary';
                    case 2: return 'text-info';
                    case 3: return 'text-warning';
                    case 4: return 'text-success';
                    case 5: return 'text-danger';
                }
            }
            
        },
            
    }
<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <b-card-title>
          Заявки на доступ к продуктам
          <feather-icon
            icon="FilterIcon"
            size="16"
            style="cursor: pointer"
            :class="!openFilter ? 'text-muted' : ''"
            @click="openFilter = !openFilter"
          />
        </b-card-title>
        <div>
          <!-- <b-button
            class="ml-1"
            pill
            variant="primary"
            :to="{
              name: 'admin-offers-add',
              params: {
                categories: categories,
                companies: companies,
                cities: cities,
              },
            }"
            >Открыть доступ</b-button
          > -->
        </div>
      </b-card-header>

      <hr v-if="openFilter" class="mt-0 mb-0" />

      <transition name="fade">
        <b-card-header v-if="openFilter">
          <b-card-title>
            <span style="font-size: 14px">Фильтры</span>
          </b-card-title>
          <div class="d-flex align-items-center">
            <b-form-select
              style="width: 200px; border-radius: 16px"
              v-model="filter.status"
              :options="statuses"
              size="sm"
              class="ml-2"
            ></b-form-select>
            <b-form-input
              style="width: 200px; border-radius: 16px"
              v-model="filter.user"
              placeholder="ID веб-мастера"
              size="sm"
              class="ml-2"
            ></b-form-input>
            <b-form-input
              style="width: 200px; border-radius: 16px"
              v-model="filter.offer"
              placeholder="ID продукта"
              size="sm"
              class="ml-2"
            ></b-form-input>
            <b-button
              @click="cleanFilter"
              pill
              variant="light"
              class="ml-2"
              style="line-height: 1; font-size: 14px; padding: 10px 16px"
            >
              Очистить
            </b-button>
          </div>
        </b-card-header>
      </transition>

      <b-skeleton-table
        v-if="is_loading"
        animation="fade"
        :columns="7"
        :rows="5"
      >
      </b-skeleton-table>

      <b-table-simple v-else class="font-small-3 text-black" responsive>
        <b-thead>
          <b-tr>
            <b-td
              >#
              <feather-icon
                v-if="orderBy.column === 'id' && orderBy.sortBy == 'desc'"
                icon="ChevronDownIcon"
                size="16"
                @click="updateOrderBy('id')"
                style="cursor: pointer"
              />
              <feather-icon
                v-else-if="orderBy.column === 'id' && orderBy.sortBy == 'asc'"
                icon="ChevronUpIcon"
                size="16"
                @click="updateOrderBy('id')"
                style="cursor: pointer"
              />
              <feather-icon
                v-else
                icon="CodeIcon"
                size="12"
                @click="updateOrderBy('id')"
                style="cursor: pointer; transform: rotate(90deg)"
              />
            </b-td>
            <b-td>Веб-мастер</b-td>
            <b-td>Продукт</b-td>
            <b-td>Статус</b-td>
            <b-td>Дата создания</b-td>
            <b-td></b-td>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="(item, index) in items" :key="item.id">
            <b-td class="fs-14 text-black font-weight-bolder">{{
              item.id
            }}</b-td>
            <b-td class="fs-14 font-weight-normal text-nowrap">
              <div class="d-flex">
                <b-avatar
                  :variant="Users.utils.resolveAvatarColor(item.user.uuid)"
                  :text="Users.utils.capitalize(`${item.user.firstName}`, 1)"
                />
                <div class="d-flex flex-column" style="margin-left: 12px">
                  <span
                    class="font-weight-bolder text-primary text-capitalize m-0"
                  >
                    {{ item.user.first_name }} {{ item.user.last_name }}
                  </span>
                  <span class="text-muted" style="font-size: 11px"
                    >#{{ item.user.uuid }}</span
                  >
                </div>
              </div>
            </b-td>
            <b-td class="fs-14 font-weight-normal text-nowrap">
              <div class="d-flex flex-column">
                <span class="text-primary text-capitalize m-0">
                  {{ item.offer.title }}
                </span>
                <span class="text-muted" style="font-size: 11px"
                  >#{{ item.offer.id }}</span
                >
              </div>
            </b-td>

            <b-td class="fs-14 text-black">
              <div class="d-flex align-items-center" v-if="item.status == 0">
                <div class="cicle-badge-view cicle-default"></div>
                <span>Отклонено</span>
              </div>
              <div
                class="d-flex align-items-center"
                v-else-if="item.status == 1"
              >
                <div class="cicle-badge-view cicle-outline-default"></div>
                <span>Создано</span>
              </div>
              <div
                class="d-flex align-items-center"
                v-else-if="item.status == 2"
              >
                <div class="cicle-badge-view cicle-outline-warning"></div>
                <span>В обработке</span>
              </div>
              <div
                class="d-flex align-items-center"
                v-else-if="item.status == 3"
              >
                <div class="cicle-badge-view cicle-outline-success"></div>
                <span>Одобрено</span>
              </div>
            </b-td>
            <b-td class="fs-14 text-black"
              >{{ item.date }} {{ item.time }}</b-td
            >
            <b-td>
              <feather-icon
                @click="openDataForm(item)"
                icon="SearchIcon"
                class="text-muted"
                size="20"
                style="margin-right: 10px; cursor: pointer"
              />
              <b-dropdown size="sm" right no-caret variant="transparent">
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    class="text-muted"
                    size="22"
                  />
                </template>
                <b-dropdown-item @click="openStatusEditor(item)">
                  <feather-icon
                    icon="EditIcon"
                    class="text-primary"
                    size="13"
                  />
                  <span class="text-primary"> Изменить статус</span>
                </b-dropdown-item>
              </b-dropdown>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <b-card-body class="d-flex justify-content-between pt-0 pb-0">
        <span class="font-weight-bolder">Всего: {{ total_count }}</span>

        <b-pagination-nav
          :value="current_page"
          :link-gen="linkGen"
          :number-of-pages="last_page"
          use-router
        ></b-pagination-nav>
      </b-card-body>
    </b-card>

    <b-modal
      v-model="modalsVisibility.data_form"
      title="Быстрый просмотр"
      size="lg"
      centered
      hide-footer
    >
      <data-form-content :item="item" />
    </b-modal>

    <b-modal
      v-model="modalsVisibility.status"
      title="Смена статуса"
      centered
      hide-footer
    >
      <status-modal-content
        :item="item"
        :statuses="statuses"
        @afterChangeStatus="afterChangeStatus"
      />
      <!--   -->
    </b-modal>
  </div>
</template>

<script>
import Users from "@/modules/admin/users/";

import DataFormContent from "./modals/dataForm";
import StatusModalContent from "./modals/status";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  data() {
    return {
      Users,
      is_loading: true,
      items: [],
      current_page: 0,
      has_more_pages: false,
      last_page: 0,
      total_count: 0,

      item: {
        status: null,
      },

      modalsVisibility: {
        data_form: false,
        status: false,
      },

      openFilter: false,

      filter: {
        user: null,
        offer: null,
        status: null,
      },

      orderBy: {
        sortBy: "desc",
        column: "id",
      },

      statuses: [
        { value: null, text: "Статус" },
        { value: 0, text: "Отклонено" },
        { value: 1, text: "Создано" },
        { value: 2, text: "В обработке" },
        { value: 3, text: "Одобрено" },
      ],
    };
  },
  methods: {
    preferDeleteAction(item, index) {
      this.$swal({
        title: `Подтвердите действие`,
        text: `Вы действительно хотите удалить предложение '${item.title}'?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Удалить",
        cancelButtonText: "Отмена",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-outline-secondary ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$request
            .post("offers/delete", {
              id: item.id,
            })
            .then((rsp) => {
              this.items.splice(index, 1);
              this.$swal({
                icon: "success",
                title: item.title,
                text: "Предложение успешно удален",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            });
        }
      });
    },

    linkGen(pageNum) {
      return {
        name: this.$route.name,
        query: {
          page: pageNum,
        },
      };
    },

    get() {
      this.is_loading = true;

      this.$request
        .get("offers/applicationsOpening.list", {
          page: this.$route.query,
          filter: this.filter,
          orderby: this.orderBy,
        })
        .then((rsp) => {
          this.items = rsp.items;
          this.total_count = rsp.total_count;
          this.current_page = rsp.current_page;
          this.last_page = rsp.last_page;
          this.is_loading = false;
        });
    },

    openDataForm(item) {
      this.item = item;
      if (typeof this.item.formData == 'string') this.item.formData = JSON.parse(item.formData);
      this.modalsVisibility.data_form = true;
    },

    openStatusEditor(item) {
      this.item = item;
      this.modalsVisibility.status = true;
    },

    cleanFilter() {
      this.filter = {
        user: null,
        offer: null,
        status: null,
      };

      this.orderBy = {
        sortBy: "desc",
        column: "id",
      };

      this.get(true);
    },

    updateOrderBy(column) {
      if (this.orderBy.column == column) {
        this.orderBy.sortBy = this.orderBy.sortBy == "desc" ? "asc" : "desc";
      } else {
        this.orderBy.sortBy = "desc";
        this.orderBy.column = column;
      }

      this.get(true);
    },

    afterChangeStatus(item) {
      this.$request
        .post("offers/applicationsOpening.changeStatus", {
          id: item.id,
          status: item.status,
        })
        .then((rsp) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              text: "Статус заявки с ID " + item.id + " изменен",
              icon: "CheckCircleIcon",
              variant: "success",
            },
          });
          this.modalsVisibility.status = false;
        });
    },
  },
  components: {
    DataFormContent,
    StatusModalContent,
  },
  watch: {
    "$route.query": function () {
      this.get();
    },
    filter: {
      deep: true,
      handler(bef) {
        this.get(true);
      },
    },
  },
  mounted() {
    this.get();
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>
